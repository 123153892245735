import React, { useState } from "react";
import "../styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { clearCart } from "../../../redux/slices/cartSlice";

const Sidebar = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selected, setSelected] = useState("DASHBOARD");

  return (
    <div className="profile-sideBar">
      {/* <div
        className={
          selected === "DASHBOARD" ? "profile-menu-selected" : "profile-menu"
        }
        onClick={() => {
          setSelected("DASHBOARD");
          navigation("/profile/dashboard");
        }}
      >
        <div className="profile-sidebar-txt1">{t("dashboard")}</div>
      </div> */}
      <div
        className={
          selected === "DETAILS" ? "profile-menu-selected" : "profile-menu"
        }
        onClick={() => {
          setSelected("DETAILS");
          navigation("/profile/details");
        }}
      >
        <div className="profile-sidebar-txt1">{t("account")}</div>
      </div>
      <div
        className={
          selected === "ORDERS" ? "profile-menu-selected" : "profile-menu"
        }
        onClick={() => {
          setSelected("ORDERS");
          navigation("/profile/orders");
        }}
      >
        <div className="profile-sidebar-txt1">{t("orders")}</div>
      </div>

      <div
        className={
          selected === "FAVOURITES" ? "profile-menu-selected" : "profile-menu"
        }
        onClick={() => {
          setSelected("FAVOURITES");
          navigation("/profile/favourites");
        }}
      >
        <div className="profile-sidebar-txt1">{t("favourites")}</div>
      </div>

      <div
        className={
          selected === "ADDRESSES" ? "profile-menu-selected" : "profile-menu"
        }
        onClick={() => {
          setSelected("ADDRESSES");
          navigation("/profile/address");
        }}
      >
        <div className="profile-sidebar-txt1">{t("addresses")}</div>
      </div>

      {/* <div
        className={
          selected === "PAYMENT" ? "profile-menu-selected" : "profile-menu"
        }
        onClick={() => {
          setSelected("PAYMENT");
          navigation("/profile/payment");
        }}
      >
        <div className="profile-sidebar-txt1">PAYMENT</div>
      </div> */}

      <div
        className={selected === "LOGOUT" ? "profile-menu" : "profile-menu"}
        onClick={() => {
          dispatch(logout(null));
          dispatch(clearCart(null));
          navigation("/");
        }}
      >
        <div className="profile-sidebar-txt2">{t("logout")}</div>
      </div>
    </div>
  );
};

export default Sidebar;
