import { Col, Row, Card, Navbar, Container } from "react-bootstrap";
import "../style.scss";
import DetailsTab from "./detailsTab";
import CartCard from "./cartCard";
import useFetch from "../../../shared/hook/fetchData";
import API from "../../../config/API";
import RelatedItems from "./RelatedItems";
import { Button, Divider, Image, Space } from "antd";
import { Rate } from "antd";
import { RiFlag2Fill } from "react-icons/ri";
import Slider from "react-slick";
import { Imagesettings } from "./imageSliderSettings";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import ProductAccordion from "./ProductAccordion";
import ProductVariants from "./ProductVariants";
import { GiSettingsKnobs } from "react-icons/gi";
import { MdAddShoppingCart } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { FaShareAlt } from "react-icons/fa";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { renderToStaticMarkup } from "react-dom/server";
import ReactDOMServer from "react-dom/server";
const html2pdf = require("html2pdf.js/dist/html2pdf.min") as any;

function ProductCard(props: any) {
  const {
    data: products,
    isLoading: loading,
    error,
  } = useFetch(
    API.PRODUCT_SEARCH +
      `bysubcategory?id=${
        props?.data?.subCategory ? props?.data?.subCategory : 0
      }&order=DESC&price=RAND&page=1&take=10`,
    false
  );
  const pdfJSX = () => {
    return (
      <div className="productDetails-margin-v-8" style={{marginLeft:"2rem"}}>
    <h1 className="productDetails-txt-bold">Specification</h1>
    <h2
      className="productDetails-secondary-text productDetails-text3"
      dangerouslySetInnerHTML={{
        __html: props?.data?.specifications,
      }}
    ></h2>
  </div>
    )
  }

  const GenerateSpecPdf = async () => {
    const printElement = ReactDOMServer.renderToString(pdfJSX());
    html2pdf().from(printElement).save(`${props?.data?.name}.pdf`);
  };
  return (
    <div className="product-landing">
      <Row>
        {/* product image Card */}
        <Col md={8}>
          <div className="productDetails-product-images">
            <div className="productDetails-image1">
              <Image
                width={"100%"}
                src={
                  props?.activeVariant?.status == true &&
                  props?.activeVariant?.variant?.image
                    ? props?.activeVariant?.variant?.image
                    : props?.data?.image
                }
                className="productDetails-ProductDetailScreen-image1"
                alt="image1"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "50%",
                  overflow: "hidden",
                  height: "auto",
                  marginTop: 10,
                }}
              >
                <Image.PreviewGroup
                  preview={{
                    onChange: (current, prev) =>
                      console.log(
                        `current index: ${current}, prev index: ${prev}`
                      ),
                  }}
                >
                  <Slider {...Imagesettings}>
                    {props?.data?.productImages?.map(
                      (item: any, index: number) => (
                        <Image src={item?.url} key={index} />
                      )
                    )}
                  </Slider>
                </Image.PreviewGroup>
              </div>
            </div>
          </div>
        </Col>
        {/* Product details */}
        <Col md={4}>
          <div className="product-details">
            {/* <div className="productDetails-details-row productDetails-margin-b-32">
              <Rate
                allowHalf
                defaultValue={4.5}
                className="productDetails-text3"
              />
              <h6 className="productDetails-secondary-text productDetails-text4 productDetails-margin-h-6">
                {props?.reviewMeta?.itemCount} Reviews
              </h6>
            </div> */}

            <Card style={{ border: "none" }}>
              <Card.Body>
                <Card.Title>
                  <h1 className="productDetails-text1 productDetails-txt-bold">
                    {props?.data?.name}
                  </h1>
                </Card.Title>
                <Card.Subtitle className="mb-2" style={{ color: "#007DA5" }}>
                  Base Product
                </Card.Subtitle>
                <Card.Text>
                  <p className="">{props?.data?.description}</p>
                  <div>
                    <hr />
                  </div>
                  <div>
                    <Button className="product-btn" href="#productVariants">
                      <GiSettingsKnobs /> &nbsp;
                      <i> Configure </i> Product variants
                    </Button>
                    <Button className="product-btn">find a Partner</Button>
                    <Button className="product-btn">
                      <MdAddShoppingCart /> &nbsp;Add to My Product
                    </Button>
                  </div>

                  <h6>Specification</h6>

                  <div className="spec">
                    <Button
                      loading={false}
                      icon={<FaDownload />}
                      title="Download"
                      className="download-btn"
                      onClick={GenerateSpecPdf}
                    >
                      Download &nbsp;
                    </Button>
                    <Button className="share-btn">
                      <FaShareAlt />
                      &nbsp; Share
                    </Button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <div className="productDetails-margin-b-32" />
        {/* <Row>
          <div className="product-details">
            <DetailsTab
              data={props?.data}
              openReview={props?.openReview}
              reviews={props?.reviews}
              deleteReview={props?.deleteReview}
              reviewMeta={props?.reviewMeta}
              reviewPage={props?.reviewPage}
              reviewPageSize={props?.reviewPageSize}
              getReviewPage={props?.getReviewPage}
              reviewLoading={props?.reviewLoading}
              addToCart={props?.addToCart}
              loadingCart={props?.loadingCart}
              addWishlist={props?.addWishlist}
              favorite={props?.favorite}
              variants={props?.variants}
              selectedVariant={props?.selectedVariant}
              setSelectedVariant={props?.setSelectedVariant}
              activeVariant={props?.activeVariant}
            />
          </div>
        </Row> */}
        {/* product varant  & spec accordions*/}
        <ProductVariants data={props?.data} />

        <div className="productDetails-margin-b-32" />
        <Divider />
        <RelatedItems
          data={products?.slice(0, 8)}
          title="You might also like.."
          size="big"
        />
      </Row>
    </div>
  );
}

export default ProductCard;
