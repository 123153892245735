
import React from "react";
import { Accordion, Row, Col, Button, Form, Table } from "react-bootstrap";
import "../style.scss";


export default function ProductAccordion(props: any) {


    return(
        <Accordion className="accordion">
          {/* Specifications */}
          <Accordion.Item eventKey="0" className="accordion-items borderCommonDash"   id="specifications">
            <Accordion.Header>
              <h5>Specifications</h5>
            </Accordion.Header>
            <Accordion.Body>
            <h6
            className="productDetails-secondary-text productDetails-text3"
            dangerouslySetInnerHTML={{
              __html: props?.data?.specifications,
            }}
          ></h6>
            </Accordion.Body>
          </Accordion.Item>
          {/* Product Supports */}
          <Accordion.Item eventKey="1" className="accordion-items borderCommonDash" id="productSupport">
            <Accordion.Header>
              <h5>Product Supports</h5>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <a href="#" className="marginBottom2 noUnderLine bold600">
                  Customer and Technical Support, Software/Firmware, Repair
                  Services
                </a>
              </div>
              <div>
                <a href="#" className="marginBottom2 noUnderLine bold600">Technical Training for CommScope</a>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="accordion-items borderCommonDash" id="documentDownload">
            <Accordion.Header>
              <h5>document & download</h5>
            </Accordion.Header>
            <Accordion.Body>
              <h6>Installation Instruction</h6>
              <hr />
              <a href="#" className="noUnderLine bold600">TECP-96-194 Cleaning Fiber Connectors and Adapters</a>
              <h6 className="marginTop1">Product Compliance Documentation</h6>
              <hr />
              <div className="marginBottom1"><a href="#" className="noUnderLine bold600">Global Trade Data</a></div>
              
              <a href="#" className="noUnderLine bold600">Statement of Compliance RoHS/Reach</a>
              <h6 className="marginTop1">Product Specification</h6>
              <hr />
              <a href="#" className="noUnderLine bold600 ">ARGRPRG7R Product specifications</a>
              <Form.Select aria-label="Default" className="marginTop1">
                <option>Choose other Language</option>
                <option value="1">Chinese Simplified</option>
                <option value="2">French</option>
                <option value="3">Greman</option>
                <option value="4">Japanese</option>
                <option value="5">Portuguese</option>
                <option value="6">Spanish</option>
              </Form.Select>
              <a href="#" className="noUnderLine bold600">ARGRPRG7R Product specifications (Comprehensive)</a>
              <Form.Select aria-label="Default" className="marginTop1">
                <option>Choose other Language</option>
                <option value="1">Chinese Simplified</option>
                <option value="2">French</option>
                <option value="3">Greman</option>
                <option value="4">Japanese</option>
                <option value="5">Portuguese</option>
                <option value="6">Spanish</option>
              </Form.Select>
              <hr />
              <h6>Warranty</h6>
              <a href="#" className="noUnderLine bold600">CommScope Limited Product Warranty</a>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="accordion-items borderCommonDash" id="relatedProductAccessories">
            <Accordion.Header>
              <h5>Related Products and Accessories</h5>
            </Accordion.Header>
            <Accordion.Body>
              <h6>Included Products</h6>
              <h6 style={{ color: "blue" }}>Cables</h6>
              <Accordion flush className="borderCommonDash accordion-items">
                <Accordion.Header>
                  <h6>Fiber Optics Cables</h6>
                  <h6>Indoor Cables(1)</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md="2"></Col>
                    <Col md="8">
                      <div>
                        <a href="#" className="noUnderLine bold600">
                          <h6>760251072 | N-016-MP-8G1-F30YL/D</h6>
                        </a>
                      </div>
                      <div>
                        Fiber indoor cable, TeraSPEED® Low Smoke Zero Halogen
                        Riser for Light Duty MPO Patchcords, 16 fiber,
                        Singlemode G.657.A2/B2, Feet jacket marking, Yellow
                        jacket color, Dca flame rating
                      </div>
                    </Col>
                    <Col md="2">
                      {" "}
                      <Button variant="outline-primary">Preview</Button>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion >
              <h6 style={{ color: "blue" }}>Connectors</h6>
              <Accordion flush className="borderCommonDash accordion-items">
                <Accordion.Header>
                  <h6>Fiber Adapters & Connectors</h6>
                  <h6>Connectors(1)</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md="2"></Col>
                    <Col md="8">
                      <div>
                        <a href="#" className="noUnderLine bold600">
                          <h6>860661399</h6>
                        </a>
                      </div>
                      <div>
                        Fiber Optic Connector Kit, singlemode, OS2, MPO-16/APC,
                        ULL, unpinned, green, for 3 mm cable
                      </div>
                    </Col>
                    <Col md="2">
                      {" "}
                      <Button variant="outline-primary">Preview</Button>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className="accordion-items borderCommonDash" id="otherBrowse">
            <Accordion.Header>
              <h5>Other Ways to Browse</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion >
                <Accordion.Item eventKey="1" className="accordion-items borderCommonDash">
                  <Accordion.Header>
                    <h6 style={{ color: "blue" }}>Browse by Category</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <a href="#" className="noUnderLine bold600"> MPO Cable Assemblies</a>
                    </div>
                    <div>
                      <a href="#" className="noUnderLine bold600">SYSTIMAX&copy; MPO Calbe Assemblies</a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="accordion-items borderCommonDash">
                  <Accordion.Header>
                    <h6 style={{ color: "blue" }}>Browse by Network</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <a href="#" className="noUnderLine bold600"> Arrays for Data Centers</a>
                    </div>
                    <div>
                      <a href="#" className="noUnderLine bold600"> High Speed Migration For Data Centers</a>
                    </div>
                    <div>
                      <a href="#" className="noUnderLine bold600">
                        SYSTIMAX&copy; Fiber Structurd Cabling for Enterprise
                        Networks
                      </a>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
    )
}