import { Row, Col, Image } from "react-bootstrap";
import { IoIosArrowRoundForward,IoIosArrowRoundBack } from "react-icons/io";
import "./HomeCard.scss";
import React from 'react'
import { useTranslation } from "react-i18next";

interface HomeCardProps {
  title: string;
  image: any;
  text: string;
  buttonText: string;
  titleColor:string
}

const HomeCardRight: React.FC<HomeCardProps> = ({
  title,
  text,
  image,
  buttonText,
  titleColor
}) => {

  const {i18n} = useTranslation()
  return (
    <Row className="Card-container" style={{margin:0}}>
      <Col  md={6} className="p-3 card-text">
        <h2 style={{color:titleColor}}>{title}</h2>
        <p>{text}</p>
        <button className='explore-button px-3 '><span className='btn-text'>{buttonText}</span> <span>{i18n.language === 'ar' ? <IoIosArrowRoundBack/> : <IoIosArrowRoundForward/>}</span></button>
      </Col>
      <Col  md={6} className='d-flex justify-content-center align-items-center'>
        <Image src={image} fluid/>
      </Col>
    </Row>
  );
};

export default HomeCardRight;
