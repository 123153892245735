import React from 'react';
import { Button, Checkbox, Popover } from 'antd';

function MobilePageSider() {

    const filterOptions =["₹ 0 TO ₹ 499", "₹ 500 TO ₹ 999", "₹ 1000 TO ₹ 1499", "₹ 1500 TO ₹ 1999", "₹ 2000 TO ₹ 2499", "₹ 2500 TO ₹ 2999"]
  const sortOptions=["High to Low", "Low to High", "New"]

    
  const filterContent = (
    <>
      {filterOptions.map((o) => (
        <div key={o}>
          <Checkbox value={o}>{o}</Checkbox>
        </div>
      ))}
    </>
  );

  const sortContent = (
    <>
      {sortOptions.map((o) => (
        <div key={o}>
          <Checkbox value={o}>{o}</Checkbox>
        </div>
      ))}
    </>
  );
  return (
    <div className='d-flex mt-2 mob-sider-container'>
    <Popover content={filterContent}>
    <div className='filter-div p-2 text-center'><span className='bottom-line'>Filter</span></div>
  </Popover>
  <div className='divider'></div>
  <Popover content={sortContent}>
    <div className='sort-div p-2 text-center'><span className='bottom-line'>Sort</span></div>
  </Popover>
    </div>
  )
}

export default MobilePageSider



