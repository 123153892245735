import { Popover } from "antd";
import React, { useState } from "react";
import "../styles.scss";
import i18next from "i18next";
import { setDirection } from "../../../redux/slices/languageSlice";
import { useDispatch } from "react-redux";
import { MdKeyboardArrowDown } from "react-icons/md";

const LanguageSelector = (props: any) => {
  const [selected, setselected] = useState("EN");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleChangeLanguage = (value: any, image: any) => {
    if (value === "ar") {
      dispatch(setDirection("RTL"));
    } else {
      dispatch(setDirection("LTR"));
    }

    setselected(value.toUpperCase());
    const language = value;
    i18next.changeLanguage(language);
  };

  const Languages = [
    {
      id: 1,
      title: "English",
      value: "en",
    },
    {
      id: 2,
      title: "Arabic",
      value: "ar",
    },
  ];

  const languages = (
    <div>
      {Languages?.map((val: any) => (
        <div
          className="language-txt1"
          onClick={() => {
            hide();
            handleChangeLanguage(val.value, val.image);
          }}
        >
          {val.title}
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <Popover
        open={open}
        placement="bottomRight"
        content={languages}
        trigger="click"
        onOpenChange={handleOpenChange}
        arrow={false}
      >
        <div className="Header-deskBox-txt2">
          India - {selected} <MdKeyboardArrowDown />
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelector;
