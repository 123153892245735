import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function ProductPopover() {
  const navigation = useNavigate();
  const Category = useSelector((state: any) => state.Category.categries);

  const [Selectcategory, setCategory] = useState<any>(
    Category.length && Category[0]?.id ? Category[0] : {}
  );

  return (
    <div style={{ minWidth: 700, maxWidth: 700 }}>
      <Container>
        <div className="Header-ProductPopovertxt1">Products</div>
        <div className="Header-ProductPopovertxt2">View all products</div>
        <hr />
        <Row>
          <Col sm={4}>
            {Category && Category.length
              ? Category.map((item: any) => (
                  <div
                    key={item.id}
                    className={
                      Selectcategory?.id === item?.id
                        ? "Header-ProductPopover active"
                        : "Header-ProductPopover"
                    }
                    onClick={() => setCategory(item)}
                  >
                    {item.name}
                  </div>
                ))
              : null}
          </Col>
          <Col sm={8} style={{ margin: 0, padding: 0 }}>
            <div className="Header-ProductPopoverBox1">
              <div className="Header-ProductPopovertxt3">
                {Selectcategory?.name}
              </div>
              <div className="Header-ProductPopovertxt4">
                {Selectcategory?.description}
              </div>
              <br />
              {Selectcategory?.sub_categories &&
                Selectcategory?.sub_categories.map((item: any) => {
                  return (
                    <div
                      key={item._id}
                      className="Header-ProductPopovertxt5"
                      onClick={() =>
                        navigation(
                          `/products/category?id=${window.btoa(
                            item._id
                          )}&type=${item.name}`
                        )
                      }
                    >
                      {item.name}
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductPopover;
