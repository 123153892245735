import React, { useEffect, useState } from "react";
import EmptyOrders from "../components/emptyOrders";
import NoData from "../../../components/noData";
import { FaBoxOpen } from "react-icons/fa";
import Loading from "../../../components/loading";
import { Avatar, List, Skeleton, Tag } from "antd";
import API from "../../../config/API";
import { useSelector } from "react-redux";
import { GET } from "../../../utils/apiCalls";
import { spawn } from "child_process";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import moment from "moment";

const ProfileOrders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<any[]>([]);
  const User = useSelector((state: any) => state.User.user.data);
  const Settings = useSelector((state: any) => state.Settings.Settings);
  const navigate = useNavigate();
  const getOrders = async () => {
    try {
      const url = API.ORDER_GET + User._id;
      const response: any = await GET(url, null);
      if (response.status) {
        setOrders(response.data);
      } else {
        throw new Error(response.message);
      }
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <>
      <div>
        {isLoading ? (
          <Loading />
        ) : orders && orders.length ? (
          <List
            className="demo-loadmore-list"
            loading={isLoading}
            itemLayout="horizontal"
            // loadMore={loadMore}
            dataSource={orders}
            size="small"
            header={<h3>{"Your Orders"}</h3>}
            renderItem={(item: any) => (
              <List.Item
                actions={[
                  <div
                    onClick={() => navigate(`/profile/orders/${item?.id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <AiOutlineEye cursor="pointer" size={25} color="#007DA5" />
                  </div>,
                ]}
              >
                <List.Item.Meta
                  // avatar={<Avatar src={item?.storeDetails?.logo_upload} />}
                  title={
                    <span className="fw-bold">
                      {item?.storeDetails?.store_name}
                    </span>
                  }
                  description={
                    <div className="ms-3">
                      {Array.isArray(item?.orderItems) == true
                        ? item?.orderItems.map((item: any) => (
                            <List.Item.Meta
                              className="mt-2"
                              avatar={
                                <Avatar
                                  shape="square"
                                  src={item?.image}
                                  size={"large"}
                                />
                              }
                              title={
                                <>
                                  <div>{`${item?.name} (${item.quantity} item)`}</div>
                                  <div>
                                    <span>Ordered on:</span>
                                    <span className="text-success">{` ${moment(
                                      item.createdAt
                                    ).format("DD/MM/YYYY")}`}</span>
                                  </div>
                                </>
                              }
                            />
                          ))
                        : "false"}
                    </div>
                  }
                />
                <div className="d-flex gap-2">
                  <div>
                    {Settings?.currency}
                    {item?.grandTotal}
                  </div>
                  <Tag bordered={false}>{item?.status}</Tag>
                </div>
              </List.Item>
            )}
          ></List>
        ) : (
          <NoData
            icon={<FaBoxOpen size={70} color="#e6e6e6" />}
            header="No Orders Found"
            text1={`You have no orders. Please start shopping at Finosel and place orders`}
            button={"START SHOPPING NOW"}
          />
        )}
      </div>
    </>
  );
};

export default ProfileOrders;
