import { Button, Form, Input, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaRegUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import "../styles.scss";
import EditModal from "./components/editModal";
import EditEmail from "./components/editEmail";

import listItems from "./helpers/listItems.json";
import EditName from "./components/editName";
// import EditPhoneNumber from "./components/editPhoneNumber";
import API from "../../../config/API";
import { GET } from "../../../utils/apiCalls";
import PhoneVerifyOtp from "./components/phoneVerify";
import DeactivateModal from "./deactivateModal";

const ProfileDashboard = () => {
  const User = useSelector((state: any) => state.User.user);

  // Edit modal--------------

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const handleeditcancel = () => {
    setModal(false);
    setModal1(false);
    setModal2(false);
  };

  // Deactivation modal--------------
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const listItems2 = [
    {
      text: "Reactivation is easy.",
      className: "profile-dashboard-txt11",
    },
    {
      text: "Simply Login with your registered email id or mobile number and password combination used prior to deactivation. Your account data is fully restored. Default settings are applied, and you will be subscribed to receive promotional emails from NextMI.",
      className: "profile-dashboard-txt12",
    },
    {
      text: "NextMi retains your account data for you to conveniently start off from where you left if you decide to reactivate your account",
      className: "profile-dashboard-txt11",
    },
    {
      text: "Remember: Account Reactivation can be done on the desktop version Only.",
      className: "profile-dashboard-txt11",
    },
  ];

  const PhoneNumberIsorNot = User?.data?.phone || User?.data?.data?.phone;

  return (
    <>
      <div>
        <Container>
          <div className="profile-dashboard-txt1">Welcome,</div>
          <div className="profile-dashboard-txt2">
            <div className="profile-dashboard-Box2">
              {User?.data?.first_name
                ? User?.data?.first_name
                : User?.data?.data?.first_name}
              {User?.data?.data?.last_name}
            </div>
            <FaRegUserCircle size={30} />
          </div>
          <br />

          <Form>
            <Row>
              <div className="profile-dashboard-Box1">
                <div className="profile-dashboard-txt5">
                  Personal Information
                </div>
                <br />
              </div>
              <Col md={4}>
                <div className="profile-dashboard-Box5">
                  {User?.data?.first_name
                    ? User?.data?.first_name
                    : User?.data?.data?.first_name}
                  &nbsp;
                  {User?.data?.last_name
                    ? User?.data?.last_name
                    : User?.data?.data?.last_name}
                </div>
              </Col>
              <Col md={1}></Col>
              <br />
              <br />
              <Col md={2}>
                <div
                  className="profile-edit-btn"
                  onClick={() => {
                    setModal1(true);
                  }}
                >
                  {User?.data?.first_name || User?.data?.data?.first_name
                    ? "Edit"
                    : "Add"}
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <div className="profile-dashboard-txt5">Email Address</div>
              <br />
              <Col md={4}>
                <div className="profile-dashboard-Box5">
                  {User?.data?.email
                    ? User?.data?.email
                    : User?.data?.data?.email}
                </div>
              </Col>
              <Col md={1}>
                {User?.data?.email || User?.data?.data?.email ? (
                  <Tag
                    style={{
                      color:
                        User?.data?.mail_verify || User?.data?.data?.mail_verify
                          ? "green"
                          : "red",
                    }}
                  >
                    {User?.data?.mail_verify || User?.data?.data?.mail_verify
                      ? " verified"
                      : " not verified"}
                  </Tag>
                ) : null}
              </Col>

              <Col md={2}>
                <div
                  className="profile-edit-btn"
                  onClick={() => {
                    setModal(true);
                  }}
                >
                  {User?.data?.email || User?.data?.data?.email
                    ? "Edit"
                    : "Add"}
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <div className="profile-dashboard-Box1">
                <div className="profile-dashboard-txt5">Mobile Number</div>
                <br />
              </div>
              <Col md={4}>
                <div className="profile-dashboard-Box5">
                  {PhoneNumberIsorNot && (
                    <>
                      <div>{User?.data?.phone || User?.data?.data?.phone}</div>
                    </>
                  )}
                </div>
              </Col>
              <Col md={1}>
                {PhoneNumberIsorNot && (
                  <>
                    <div>
                      <Tag
                        style={{
                          color:
                            User?.data?.phone_verify ||
                            User?.data?.data?.phone_verify
                              ? "green"
                              : "red",
                        }}
                      >
                        {User?.data?.phone_verify ||
                        User?.data?.data?.phone_verify
                          ? " verified"
                          : " not verified"}
                      </Tag>
                    </div>
                  </>
                )}
              </Col>

              <Col md={2}>
                <div
                  className="profile-edit-btn"
                  onClick={() => {
                    setModal2(true);
                  }}
                >
                  {PhoneNumberIsorNot ? "Edit" : "Add"}
                </div>
              </Col>
            </Row>
            <hr />
          </Form>
          <br />
          <div>
            <div className="profile-dashboard-txt6">FAQs</div>
            <br />
            <p className="profile-dashboard-txt7">
              What happens when I update my email address (or mobile number)?
            </p>
            <p className="profile-dashboard-txt8">
              Your login email id (or mobile number) changes, likewise. You'll
              receive all your account related communication on your updated
              email address (or mobile number).
            </p>
            <p className="profile-dashboard-txt7">
              When will my NextMe account be updated with the new email address
              (or mobile number)?
            </p>
            <p className="profile-dashboard-txt8">
              It happens as soon as you confirm the verification code sent to
              your email (or mobile) and save the changes.
            </p>
            <p className="profile-dashboard-txt7">
              What happens to my existing NextMe account when I update my email
              address (or mobile number)?
            </p>
            <p className="profile-dashboard-txt8">
              Updating your email address (or mobile number) doesn't invalidate
              your account. Your account remains fully functional. You'll
              continue seeing your Order history, saved information and personal
              details.
            </p>
            <p className="profile-dashboard-txt7">
              Does my Seller account get affected when I update my email
              address?
            </p>
            <p className="profile-dashboard-txt8">
              NextMe has a 'single sign-on' policy. Any changes will reflect in
              your Seller account also.
            </p>
            <br />
            <div className="profile-dashboard-txt9" onClick={showModal}>
              Deactivate Account
            </div>
          </div>
          <br />
        </Container>
        
      </div>

      {modal ? (
        <EditModal
          ui={<EditEmail close={handleeditcancel} />}
          open={modal}
          close={handleeditcancel}
        />
      ) : null}
      {modal1 ? (
        <EditModal
          ui={<EditName close={handleeditcancel} />}
          open={modal1}
          close={handleeditcancel}
        />
      ) : null}
      {modal2 ? (
        <EditModal
          ui={<PhoneVerifyOtp close={handleeditcancel} />}
          open={modal2}
          close={handleeditcancel}
        />
      ) : null}
      <DeactivateModal open={isModalOpen} cancelModal={handleCancel}/>
    </>
  );
};

export default ProfileDashboard;
