import { Col, Row } from "react-bootstrap";
import Sidebar from "./components/sidebar";
import MainArea from "./components/mainArea";
import React from "react";

function ProfileScreen() {
  return (
    <div className="Screen-box">
      <div className="">
        <br />
        <Row>
          <Col md="2">
            {/* <div className="Profile-Sidebar"> */}
            <Sidebar />
            {/* </div> */}
          </Col>
          <Col md="10">
            <div className="ProfileScreen-box">
              <MainArea />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default ProfileScreen;
