import { Avatar, Card } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

function ProductItems(props: any) {
  const Settings = useSelector((state: any) => state.Settings.Settings);
  return (
    <div>
      {Array.isArray(props?.data?.orderItems) == true
        ? props?.data?.orderItems.map((item: any) => (
            <Card bordered={false} className="mt-2">
              <Meta
                avatar={<Avatar src={item.image} size={60} shape="square" />}
                title={item.name}
                description={
                  <div className="text-dark">
                    <div>Seller: {props?.data?.storeDetails?.store_name}</div>
                    <div>Quantity: {item?.quantity}</div>
                    <div>Price: {Settings.currency}{item?.price}</div>
                    <h6 className="text-dark fw-medium my-0">Total: {Settings.currency}{item?.totalPrice}</h6>
                    <div>Ordered on: {moment(item.createdAt).format("DD/MM/YYYY")}</div>
                  </div>
                }
              />
            </Card>
          ))
        : null}
    </div>
  );
}

export default ProductItems;
