import React from "react";
import { Collapse, Drawer } from "antd";
import { useSelector } from "react-redux";
import DrawerProfile from "./drawerProfile";
import { MdFormatListBulleted } from "react-icons/md";
import { FaRegNewspaper } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { MdOutlineContacts } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RiGroupLine } from "react-icons/ri";

function SideBar(props: any) {
  const Category = useSelector((state: any) => state.Category.categries);
  const { Panel } = Collapse;
  const navigate = useNavigate();

  return (
    <Drawer
      title={<DrawerProfile />}
      placement={"left"}
      width={280}
      onClose={props?.close}
      open={props?.open}
      key={"left"}
      closeIcon={false}
    >
      <div style={{ marginTop: -20 }}>
        <div className="Sidebar-itemBox">
          <MdFormatListBulleted size={27} color="#757575" />
          <span>Products</span>
        </div>
        {/* <hr /> */}
        {/* <div className="Sidebar-txt1">Products</div> */}
       
          {Category?.map((item: any, idx: number) => {
            return (
              <>
                {item?.sub_categories?.map((subCat: any, i: number) => {
                  return (
                    <div
                      key={i}
                      className="Sidebar-txt2"
                      onClick={() => {
                        navigate(
                          `/products/category?id=${window.btoa(
                            subCat._id
                          )}&type=${subCat.name}`
                        );
                        props?.close();
                      }}
                    >
                      {subCat?.name}
                    </div>
                  );
                })}
              </>
            );
          })}
        <hr />
        <div
          className="Sidebar-itemBox"
          onClick={() => {
            props?.check();
            props.close();
            navigate("/support");
          }}
        >
          <BiSupport size={27} color="#757575" />
          <span>Support</span>
        </div>
        <div
          className="Sidebar-itemBox"
          onClick={() => {
            props?.close();
            navigate("/reach-us");
          }}
        >
          <MdOutlineContacts size={27} color="#757575" />
          <span>Reach Us</span>
        </div>
        <div
          className="Sidebar-itemBox"
          onClick={() => {
            navigate("/about-us");
            props?.close();
          }}
        >
          <RiGroupLine size={27} color="#757575" />
          <span>About us</span>
        </div>
        <div
          className="Sidebar-itemBox"
          onClick={() => {
            navigate("/news");
            props?.close();
          }}
        >
          <FaRegNewspaper size={27} color="#757575" />
          <span>News</span>
        </div>
      </div>
    </Drawer>
  );
}
export default SideBar;
