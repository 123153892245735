import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import "../style.scss";
import ProductAccordion from "./ProductAccordion";

export default function ProductVariants(props: any) {
  return (
    <>
      <Container id="productVariants">
        <Card className="product-variants">
          <Card.Body>
            <Card.Title>
              <h3>Product Variants</h3>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Configure and display product variants of ARGRPRG7R by selecting
              options
            </Card.Subtitle>
            {/* form input for product variants */}
            <Form >
              <Row>
                <div className="centerDiv">
                  <Col md="6" className="product-variants-form-item">
                    <Form.Group className="mb-3 " controlId="formBasic">
                      <Form.Label>Jacket Color</Form.Label>
                      <Form.Select aria-label="Default select" className="borderCommon">
                        <option selected value="1">
                          J-Yellow
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </div>
                <div className="centerDiv">
                  <Col md="6" className="product-variants-form-item">
                    <Form.Group className="mb-3 " controlId="formBasic">
                      <Form.Label>Breakout Length/Option</Form.Label>
                      <Form.Select aria-label="Default select" className="borderCommon">
                        <option selected value="1">
                          A - no Breakout (end A/end B), no gland ,no pulling
                          grip
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </div>
              </Row>
              <Row>
                <div className="centerDiv">
                  <Col md="3">
                    <Form.Group className="mb-3 " controlId="formBasic">
                      <Form.Label>Breakout Length/Option</Form.Label>
                      <Form.Control type="number" className="borderCommon"></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md="1" className="scaleRadio">
                    <Form.Check type="radio" name="scale" label="m" />
                    <Form.Check type="radio" name="scale" label="ft" />
                  </Col>
                </div>
                <div className="centerDiv">
                  <Col md="1">
                    <Button variant="primary" type="submit" className="borderCommon product-variant-form-btn">
                      Submit
                    </Button>
                  </Col>
                </div>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {/* display form data */}
        <Table responsive className="variants-table borderCommon">
          <thead>
            <tr>
              <th className="variants-table-th">Part Number</th>
              <th className="variants-table-th">Jack Color</th>
              <th className="variants-table-th">Breakout Length/Options</th>
              <th className="variants-table-th">Cord Length</th>
            </tr>
          </thead>
          <tbody>
            <div>No configured items found.</div>
          </tbody>
        </Table>
      </Container>
      <Container>
        {/* spec accordion */}
        <ProductAccordion data={props?.data} />
      </Container>
    </>
  );
}
