import {Row,Col} from 'react-bootstrap'
import './ChooseFinosel.scss'
import { useTranslation } from 'react-i18next';
import React from 'react'

function ChooseFinosel() {
    const { t } = useTranslation();
  return (
    <div className='choose-final-container'>
        <h1 className='choose-final-title'>{t('why_choose_finosel')}</h1>
        <Row className=''>
            <Col md={4}>
                <h4>{t('cutting_edge_technology')}</h4>
                <div className='line py-2'></div>
                <p>{t('cutting_edge_txt')}</p>
            </Col >
            <Col md={4}>
                <h4>{t('reliability')}</h4>
                <div className='line py-2'></div>
                <p>{t("reliability_txt")}</p>
            </Col>
            <Col md={4}>
            <h4>{t('innovation')}</h4>
            <div className='line py-2'></div>
                <p>{t('innovation_txt')}</p>
            </Col>
        </Row>

    </div>
  )
}

export default ChooseFinosel