import {Row,Col,Image} from 'react-bootstrap'
import mainSectionImage from '../../../assets/images/main-section-image.png';
import { IoIosArrowRoundForward,IoIosArrowRoundBack } from "react-icons/io";
import './FirstSection.scss'
import { useTranslation } from 'react-i18next';
import React from 'react'

function FirstSection() {
    const { t , i18n } = useTranslation();
  return (
        <Row className='first-section-container'>
          
            <Col xs={12} md={6}  className='d-flex flex-column justify-content-center'>
                <h1 className='main-title'>{t('intelligent_networks')}</h1>
                <p className='welcome-text'>{t('section_1_txt')}</p>
                <div className='button-container'>

                <button className='read-more-btn px-3 '><span className='btn-text'>{t('read_more')}</span> <span >{i18n.language === 'ar' ? <IoIosArrowRoundBack/> : <IoIosArrowRoundForward/>}</span></button>
                </div>
            </Col>
            <Col xs={12} md={6} className='d-flex justify-content-center align-items-center'>
                <Image src={mainSectionImage} fluid/>
            </Col>

        </Row>
        
  )
}

export default FirstSection