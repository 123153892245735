import React, { useState } from "react";
import Loading from "../../../components/loading";
import { Col, Row } from "react-bootstrap";
import NoData from "../../../components/noData";
import "../style.scss";
import PaymentOptionCard from "./paymentOptionCard";
import { Button } from "antd";
import ProceedToNextStep from "./proceedCard";
import AdressCard from "./addressCard";
import { TiTick } from "react-icons/ti";

function Step2(props: any) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <h6 className="ms-5">DELIVERY ADDRESS </h6>
      <Row>
        <Col md="8">
          <AdressCard data={props?.selectedAddress} type={true} />
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {" "}
          <Button className="mt-3 ms-3" onClick={props?.goBack}>
            Change Address
          </Button>
        </Col>
      </Row>
      <h4 className="py-2 address-screen-title text-light ps-3">
        Payment Options
      </h4>
      {props?.paymentOptions.map((item: any) => (
        <PaymentOptionCard
          data={item}
          selectPayment={props?.selectPayment}
          selectedPayment={props?.selectedPayment}
        />
      ))}

      <div className="mt-3 ms-3">
        Order confirmation will be sent to{" "}
        <span className="fw-medium">{props?.User?.data?.email}</span>
      </div>
    </>
  );
}

export default Step2;
