import { Card, Col, Row } from 'react-bootstrap'
import { RiDownloadLine } from "react-icons/ri";
import { IoIosArrowRoundForward } from "react-icons/io";
import tools1 from '../../../assets/images/tools1.png';
import tools2 from '../../../assets/images/tools2.png';
import tools3 from '../../../assets/images/tools3.png';
import tools4 from '../../../assets/images/tools4.png';
import tools5 from '../../../assets/images/tools5.png';
import tools6 from '../../../assets/images/tools6.png';
import './ToolsAccessories.scss'
import { useTranslation } from 'react-i18next';
import React from 'react'

function ToolsAccessories() {
  const { t } = useTranslation();
  return (
    <div className='tools-accessories-container'>
        <h3>{t('tools_accessories')}</h3>
        <Row>
        <Col md={4}>
          <Card className="my-3 p-4">
            <Card.Img src={tools1} variant="" />
            <Card.Body className='px-0'>
              <Card.Title as="h5" className='card-title-text'>
               {t('tools_accessories')}
              </Card.Title>
              <Card.Text as="p" className='py-3 card-para-text'>{t('tools_accessories_card_body_txt')}</Card.Text>
              <Card.Text as="div"><button className='more-btn'>{t('download_data_sheet')} <RiDownloadLine/></button></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3 p-4">
            <Card.Img src={tools2} variant="" />
            <Card.Body className='px-0'>
              <Card.Title as="h5" className='card-title-text'>
               {t('tools_accessories')}
              </Card.Title>
              <Card.Text as="p" className='py-3 card-para-text'>{t('tools_accessories_card_body_txt')}</Card.Text>
              <Card.Text as="div"><button className='more-btn'><span >{t('more_info')}</span><IoIosArrowRoundForward/></button></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3 p-4">
            <Card.Img src={tools3} variant="" />
            <Card.Body className='px-0'>
              <Card.Title as="h5" className='card-title-text'>
               {t('tools_accessories')}
              </Card.Title>
              <Card.Text as="p" className='py-3 card-para-text'>{t('tools_accessories_card_body_txt')}</Card.Text>
              <Card.Text as="div"><button className='more-btn'>{t('more_info')}<IoIosArrowRoundForward/></button></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        </Row>
        <Row>
        <Col md={4}>
          <Card className="my-3 p-4">
            <Card.Img src={tools4} variant="" />
            <Card.Body className='px-0'>
              <Card.Title as="h5" className='card-title-text'>
               {t('tools_accessories')}
              </Card.Title>
              <Card.Text as="p" className='py-3 card-para-text'>{t('tools_accessories_card_body_txt')}</Card.Text>
              <Card.Text as="div"><button className='more-btn'>{t('download_data_sheet')} <RiDownloadLine/></button></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3 p-4">
            <Card.Img src={tools5} variant="" />
            <Card.Body className='px-0'>
              <Card.Title as="h5" className='card-title-text'>
               {t('tools_accessories')}
              </Card.Title>
              <Card.Text as="p" className='py-3 card-para-text'>{t('tools_accessories_card_body_txt')}</Card.Text>
              <Card.Text as="div"><button className='more-btn'>{t('more_info')}<IoIosArrowRoundForward/></button></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3 p-4">
            <Card.Img src={tools6} variant="" />
            <Card.Body className='px-0'>
              <Card.Title as="h5" className='card-title-text'>
               {t('tools_accessories')}
              </Card.Title>
              <Card.Text as="p" className='py-3 card-para-text' >{t('tools_accessories_card_body_txt')}</Card.Text>
              <Card.Text as="div"><button className='more-btn'>{t('more_info')}<IoIosArrowRoundForward/></button></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        </Row>
    </div>
  )
}

export default ToolsAccessories