import "./styles.scss";
import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoSearchOutline } from "react-icons/io5";
import { Popover } from "antd";

import logo from "../../assets/images/finosel-logo.png";
import LanguageSelector from "./components/languageSelector";
import ProductPopover from "./components/productPopover";

function Desktop() {
  const { t } = useTranslation();
  const navigation = useNavigate();

  return (
    <div>
      <div style={{ backgroundColor: "#F2F1EE", height: 30 }}>
        <Container>
          <div className="Header-desktop-langBox">
            <div className="Header-deskBox-txt2">{t("careers")}</div>
            <div style={{ marginRight: 20 }} />
            <div>
              <LanguageSelector />
            </div>
          </div>
        </Container>
      </div>
      <Container style={{ height: "65px" }}>
        <div className="Header-deskBox">
          <div onClick={() => navigation("/")}>
            <img src={logo} className="Header-deskLogo" alt="logo" />
          </div>
          <div style={{ flex: 1 }}></div>
          <div className="Header-deskBox-txt1" onClick={()=> navigation("/about_us")}>{t("about_us")}</div>
          <div style={{ margin: 20 }} />
          <Popover arrow={false} content={<ProductPopover />} trigger="hover">
            <div className="Header-deskBox-txt1">
              {t("products")}
              <MdKeyboardArrowDown />
            </div>
          </Popover>
          <div style={{ margin: 20 }} />
          <div className="Header-deskBox-txt1" onClick={() => navigation("/")}>
            {t("support")}
          </div>
          <div style={{ margin: 20 }} />
          <div className="Header-deskBox-txt1" onClick={() => navigation("/")}>
            {t("reach_us")}
          </div>
          <div style={{ margin: 20 }} />
          <div className="Header-deskBox-txt1" onClick={() => navigation("/")}>
            {t("news")}
          </div>
          <div style={{ flex: 1 }}></div>
          <div className="Header-deskBox-txt1">
            <IoSearchOutline size={25} />
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Desktop;
