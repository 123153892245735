import { Row, Col, Image, } from "react-bootstrap";
import { IoIosArrowRoundForward ,IoIosArrowRoundBack} from "react-icons/io";
import "./HomeCard.scss";
import React from 'react'
import { useTranslation } from "react-i18next";

interface HomeCardLeftProps {
  title: string;
  image: any;
  text: string;
  buttonText:string
}

const HomeCardLeft: React.FC<HomeCardLeftProps> = ({
  title,
  text,
  image,
  buttonText
}) => {
  const {i18n} = useTranslation()
  return (
    <Row className="Card-container" style={{margin:0}}>
      <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} className='d-flex justify-content-center align-items-center'>
        <Image src={image} fluid/>
      </Col>
      <Col  md={6} className="card-text p-3">
        <h2>{title}</h2>
        <p>{text}</p>
        <button className='explore-button px-3 '><span className='btn-text'>{buttonText}</span> <span>{i18n.language === 'ar' ? <IoIosArrowRoundBack/> : <IoIosArrowRoundForward/>}</span></button>
      </Col>
    </Row>
  );
};

export default HomeCardLeft;
