import React from "react";
import FeedBack from "./feedback";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import i18next from 'i18next';
import { useDispatch } from 'react-redux';   
import './styles.scss'
import { setDirection } from "../../redux/slices/languageSlice";
import LanguageSelector from "../header/components/languageSelector";

const Footer = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch();

  const changeLanguage = (lng:string) => {
    if(lng==='ar'){
      dispatch(setDirection("RTL"));
    }else{
      dispatch(setDirection("LTR"));
    }
    i18next.changeLanguage(lng);
  };
  return (
    <div className="footer-container">
      <div className="d-flex justify-content-end">
        <FaLinkedin  size={30}/>
        <FaTwitterSquare  size={30}/>
      </div>
      <Row className="py-5 d-flex justify-content-between links-container">
        <Col xs={12} md={5}>
          <h5>{t('products')}</h5>
          <div className="line pt-1"></div>
          <Row className=" ">
            <Col md={6}>
              <p>{t('copper_products')}</p>
              <p>{t('section_7_title')}</p>
              <p>{t('security_and_resiliency')}</p>
            </Col>
            <Col md={6}>
              <p>{t('Cabinets_pdus')}</p>
              <p>{t('tools_accessories')}</p>
              <p>{t('fiber_products')}</p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={5} className="">
          <h5 className="aboutus-text">{t('about_us')}</h5>
          <div className="line pt-1"></div>
          <Row className=" ">
            <Col md={6}>
              <p>{t('alliances')}</p>
              <p>{t('contact_us')}</p>
              <p>{t('locations')}</p>
            </Col>
            <Col md={6}>
            <Link to="/careers"  className="footer-links"><p>{t('careers')}</p></Link>
              <p>{t('leadership')}</p>
              <p>{t('news')}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between links-row">
        <Col className="d-flex links text-center" md={6} style={{display:"flex",flexWrap:"wrap"}}>
          <p className="privacy-link ">{t('privacy')}</p>
          <span>|</span>
          <Link to="/terms-and-conditions" className="footer-links"><p className="px-2">{t('terms')}</p></Link>
          <span>|</span>
          <p className="px-2">{t('security')}</p>
          <span>|</span>
          <p className="px-2">{t('csr_policy')}</p>
          <span>|</span>
          <p className="px-2">{t('certifications')}</p>
          <span>|</span>
          <p className="px-2">{t('sitemap')}</p>
        </Col>
        <Col className=" dropdown-container" md={6}>
        <LanguageSelector/>
        </Col>
      </Row>
      <div>
        <span className="copy-right-text">
          {/* Copyright © 2023 Finosel. All rights reserved */}
          {t('copyright_txt')}
        </span>
      </div>
    </div>
  );
};

export default Footer;
