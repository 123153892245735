import { Col, Row, Card } from "react-bootstrap";
import MaryJ from '../../../assets/images/maryj.png'
import SaraL from '../../../assets/images/saral.png'
import Robert from '../../../assets/images/robert.png'
import "./CustomerTestimonial.scss";
import { useTranslation } from "react-i18next";
import React from 'react'

function CustomerTestimonial() {
  const { t } = useTranslation();
  return (
    <div className="customer-testimonial-container">
      <h3 className="pb-3">{t('customer_testimonials')}</h3>
      <p className="testimonial-text" >{t('customer_testimonials_intro')}</p>
      <Row className="my-3">
        <Col md={4}>
          <Card className="my-3 p-3">
            <Card.Img src={MaryJ} variant="" />
            <Card.Body>
              <Card.Text as="div" className="card-quote">"{t('mary_quote')}"</Card.Text>
              <Card.Text as="p" className="pt-3"><strong>{t('mary_j')}</strong></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3 p-3">
            <Card.Img src={SaraL} variant="" />
            <Card.Body>
              <Card.Text as="div" className="card-quote">"{t('sarah_quote')}"</Card.Text>
              <Card.Text as="p" className="pt-3"><strong>{t('sarah_L')}</strong></Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3 p-3">
            <Card.Img src={Robert} variant="" />
            <Card.Body>
              <Card.Text as="div" className="card-quote">"{t('robert_quote')}"</Card.Text>
              <Card.Text as="p" className="pt-3"><strong>{t('robert')}</strong></Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerTestimonial;
