import { Row, Col } from "react-bootstrap";
import { IoIosArrowRoundForward } from "react-icons/io";
import "./SectionSection.scss";
import { useTranslation } from 'react-i18next';
import React from 'react'

function SecondSection() {
  const { t } = useTranslation();
  return (
    <Row className="second-section-container">
      <Col xs={12} md={4}>
        <h4 className="main-text">
          {t("section_2_left_txt")}
        </h4>
      </Col>
      <Col xs={12} md={8} className="p-3 left-column">
        <p className="mb-4">
          {t("section_2_right_1")}
        </p>
        <p>
         {t("section_2_right_2")}
        </p>
        <button className="readMore-btn">{t("read_more")}<span><IoIosArrowRoundForward/></span></button>
      </Col>
    </Row>
  );
}

export default SecondSection;
