import { useNavigate } from "react-router-dom";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import Logo from "../../assets/images/finosel-logo.png";
import { useState } from "react";
import SideBar from "./sideBar";
import React from "react";
import LanguageSelector from "./components/languageSelector";

function Mobile() {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    console.log("close clicked");
  };

  return (
    <div className="Header-mobkBox">
      <div className="Header-mobkBox2" style={{ flex: 1 }}>
        <div style={{ margin: 5 }} />
        <div onClick={() => showDrawer()}>
          <HiOutlineMenuAlt2 size={30} />
        </div>
        <div style={{ margin: 10 }} />
        <div onClick={() => navigation("/")}>
          <img src={Logo} alt="logo" />
        </div>
        <div style={{ margin: 10 }} />
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "end",
            marginRight: 10,
            marginLeft: 10,
          }}
        >
          <LanguageSelector />
        </div>
        <SideBar open={open} close={onClose} />
      </div>
    </div>
  );
}
export default Mobile;
