import menu from "./siderMenu.json";
import "../styles.scss";
import React, { useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { Checkbox, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

const PageSider = () => {
  const location = useLocation();
  const filterOptions =["₹ 0 TO ₹ 499", "₹ 500 TO ₹ 999", "₹ 1000 TO ₹ 1499", "₹ 1500 TO ₹ 1999", "₹ 2000 TO ₹ 2499", "₹ 2500 TO ₹ 2999"]
  const sortOptions=["High to Low", "Low to High", "New"]
  return (
    <Accordion defaultActiveKey="0" className="ProductByCat-SiderBox mt-4">
  
      <>
      <Accordion.Item eventKey='0' className="item-container m-2">
      <Accordion.Header className="custom-accordion-header">Filter</Accordion.Header>
      {filterOptions.map((o)=>(
      <Accordion.Body className="select-container">
         <Checkbox value={o}>{o}</Checkbox>
      </Accordion.Body>

      ))}
   
      </Accordion.Item>
      <div className="divider"></div>
      <Accordion.Item eventKey='1' className="item-container m-2">
      <Accordion.Header className="custom-accordion-header">Sort</Accordion.Header>
      {sortOptions.map((s)=>(
      <Accordion.Body className="select-container">
         <Checkbox value={s}>{s}</Checkbox>
      </Accordion.Body>

      ))}
   
      </Accordion.Item>
      </>

  </Accordion>
   
   
)};
export default PageSider;