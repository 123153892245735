import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import moment from "moment";
import React from "react";

function OrderStatusCard(props:any) {
  return (
    <Card bordered={false}>
      <Meta
        title={`Order Status: ${props?.data?.orderStatus?.status}`}
        description={
          <div className="text-dark">
            <div>Order Date: {moment(props?.data?.orderStatus?.createdAt).format("DD/MM/YYYY")}</div>
          </div>
        }
      />
    </Card>
  );
}

export default OrderStatusCard;
