import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  // apiKey: "AIzaSyA4RaJedJ6JXRxKF0LLoFGv9roETDnFd4A",
  // authDomain: "finosal-project.firebaseapp.com",
  // projectId: "finosal-project",
  // storageBucket: "finosal-project.appspot.com",
  // messagingSenderId: "48708681024",
  // appId: "1:48708681024:web:524e3791e3c4a4ff764ea5",
  // measurementId: "G-13KYF3HPVL",
  apiKey: "AIzaSyDkXO9ARyLonlsOwSrbbPqQpNp6FYYjXGk",
  authDomain: "connect-ecommerce.firebaseapp.com",
  projectId: "connect-ecommerce",
  storageBucket: "connect-ecommerce.appspot.com",
  messagingSenderId: "747519192386",
  appId: "1:747519192386:web:92f9bfa87b23b5ab82acdb",
  measurementId: "G-NGW5GHRCTQ",
};
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const GoogleProvide = new GoogleAuthProvider();
export { auth, GoogleProvide };
