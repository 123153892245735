import React from "react";
import "./styles.scss"
import { Container } from "react-bootstrap";
const AboutUs = () => {
  return (
    <>
   
    <div className="about-us">
      <header className="banner">
        <h1>Finosel Intelligent Networks</h1>
      </header>
    <Container>

      <div className="about-section">
        <h2>About Us</h2>
        <p>
          Finosel Intelligent Networks, an international company with
          headquarters in England, UK, and affiliates in the Middle East, is an
          ISO9001:2015 registered company and the global provider of passive and
          active networking components. We are committed to innovation and
          excellence, bringing freshness to the networking industry. With our
          innovative product range and professional management, we offer the
          highest quality products that distinguish themselves from others with
          uncompromising performance and maximum reliability at competitive
          costs.
        </p>
        <p>
          The company has extensive project experience, a global presence, and
          an internationally established partner network. Finosel leverages
          extensive worldwide resources to meet customer needs on a local,
          regional, and global level.
        </p>
        <p>Finosel leverages extensive worldwide resources to meet customer needs on a local, regional, and global level. With our one-stop-shop approach, customers have the opportunity to minimize costs by consolidating their shipments of accessories, active components, testing equipment, and copper and structured cabling.        Supported by superior customer service and a technical support team, we ensure our customers receive the service they deserve.
        </p>
        
      </div>

     
        <div className="mission">
          <h3>Mission</h3>
          <p className="AboutUs_texts">
          The networking industry is evolving rapidly, and the constant changes must be understood as a requirement rather than a liability. Finosel recognizes the need to keep up with the pace, and for that, we are constantly evolving ourselves to provide the best products in the market. We utilize the latest technology like product design with CAD programs, manufacturing with high precision machines, and ensure that the product passes through rigorous quality control. We create opportunities for our customers to generate new revenue and avoid major capital expenditures by helping them better utilize their communication plant, primarily in the last mile. Our products increase effective channel capacity, improve quality service, and enable our customers to implement entirely new services.
          </p>
        </div>
        <div className="vision">
          <h3>Vision</h3>
          <p className="AboutUs_texts">
            To be the premier global leader in connectivity solutions, driving
            innovation, reliability, and customer satisfaction.
          </p>
        </div>
   

   

    
    </Container>
    </div>
    </>
  );
};

export default AboutUs;
